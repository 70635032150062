const AUTH_URL = `${process.env.GATSBY_DKPK_BASE_API_URL}/auth/login`;
const AUTH_VERIFY_URL = `${process.env.GATSBY_DKPK_BASE_API_URL}/auth/verify`;
const KEY = 'auth_token';

async function login() {
  try {
    if (typeof window === 'undefined') {
      return;
    }

    async function _login() {
      const response = await fetch(AUTH_URL);
      const { data } = await response.json();
      localStorage.setItem(KEY, data?.token);
      return;
    }

    const token = localStorage.getItem(KEY);

    if (token) {
      try {
        return await fetch(AUTH_VERIFY_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        return _login();
      }
    }

    return _login();
  } catch (error) {
    console.error(error);
  }
}

module.exports = login;
