const trackReferrer = function () {
  if (document && typeof document === 'undefined') {
    return;
  }

  if (!document.referrer) {
    console.info(`🤦‍♂️ seems like there is no referrer this time.`);
    return;
  }

  const trackingUrl = `${process.env.GATSBY_DKPK_BASE_API_URL}/t/referrer`;
  fetch(trackingUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referrer: document.referrer,
    }),
  })
    .then((res) => res.json())
    .then(console.log);
};

module.exports = trackReferrer;
