const login = require('./src/lib/browser/login');
const trackViews = require('./src/lib/browser/track-views');
const trackReferrer = require('./src/lib/browser/track-referrer');
require('./src/static/scss/prism/prism-tomorrow.scss');

exports.onRouteUpdate = function ({ location }) {
  trackViews(location);
};

exports.onClientEntry = function () {
  login().then(trackReferrer);

  // Vercel analytics script
  const { inject } = require('@vercel/analytics');
  inject();
};
