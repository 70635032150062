const routeAliases = {
  '/': 'home',
  '/blog/': '/blog/index/',
  '/work/': '/work/index/',
};

const trackViews = (location) => {
  const { pathname } = location,
    currentLocation = routeAliases[pathname] || pathname,
    BASE_API_URL = `https://api.kasipavankumar.in/api`,
    viewsEndpoint = `${BASE_API_URL}/views?slug=${currentLocation}`,
    fetchOpts = {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    };

  const handleData = () => {
    if (process.env.NODE_ENV === 'development') {
      console.info(
        `%cTracking views from \`gatsby-browser.js\`.`,
        'color: #17a2b8;'
      );
    }
  };

  fetch(viewsEndpoint, fetchOpts)
    .then((res) => res.json())
    .then(handleData)
    .catch(console.error);
};

module.exports = trackViews;
